var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('transition-group', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.message ? _c('p', {
    key: "message",
    staticClass: "green--text"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]) : _vm._e(), _vm.error && _vm.getType(_vm.error) === 'string' ? _c('p', {
    key: "error",
    staticClass: "red--text"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e(), _vm.getType(_vm.error) === 'object' ? _c('ul', {
    key: "error-list",
    staticClass: "red--text"
  }, _vm._l(_vm.errorKeys, function (key) {
    return _c('li', {
      key: key
    }, [_c('b', {
      staticClass: "font-bold capitalize"
    }, [_vm._v(_vm._s(_vm._f("titleCase")(key)))]), _c('ul', {
      staticClass: "comma-list"
    }, _vm._l(_vm.getErrors(key), function (item, index) {
      return _c('li', {
        key: "".concat(index, "-error")
      }, [_vm._v(" " + _vm._s(item) + " ")]);
    }), 0)]);
  }), 0) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }