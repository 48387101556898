<template>
  <v-app>
    <section class="login-section">
      <div class="login-blk">
        <div class="screen-lft">
          <div class="logo">
            <a href="#">
              <img src="@/assets/images/login.jpg" alt="" />
            </a>
          </div>
        </div>
        <div class="screen-rgt">
          <div class="screen-outer">
            <div class="reset-blk-success" v-if="success">
              <v-row align="center" justify="center" class="text-center ">
                <v-col>
                  <v-icon size="90" color="#27AE60">
                    $checkCircleFilledIcon
                  </v-icon>
                </v-col>
                <v-col cols="12">
                  <div class="success-message my-5">
                    <p class="mb-2">パスワードが更新されました。</p>
                    <p>次回から新しいパスワードでログインできます。</p>
                  </div>
                </v-col>
                <v-col cols="12" class="login-link pt-5">
                  <router-link to="/login">
                    ログイン画面
                  </router-link>
                </v-col>
              </v-row>
            </div>
            <div class="reset-blk" v-else>
              <div class="screen-title">
                <h2>パスワード再設定</h2>
                <FlashMessage :error="error" />
              </div>
              <div class="screen-form">
                <v-form v-model="isValid">
                  <!-- <v-text-field
                  v-model="email"
                  :rules="rules.email"
                  placeholder="Email address"
                  filled
                  rounded
                  dense
                  class="input-text"
                ></v-text-field>

                <div class="marin-bottom-field"></div> -->

                  <v-text-field
                    v-model="new_password"
                    :rules="rules.new_password"
                    placeholder="パスワード"
                    :type="'password'"
                    filled
                    rounded
                    dense
                    class="input-text"
                    hide-details="auto"
                    autocomplete="new-password"
                  ></v-text-field>

                  <div class="marin-bottom-field"></div>

                  <v-text-field
                    v-model="confirm_password"
                    :rules="rules.confirm_password"
                    placeholder="パスワード"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    hide-details="auto"
                    filled
                    rounded
                    dense
                    class="input-text"
                  ></v-text-field>

                  <div class="marin-bottom-field"></div>
                  <v-btn
                    block
                    class="text-capitalize btn-submit"
                    large
                    color="primary"
                    :disabled="!isValid"
                    @click="submit"
                    :loading="isLoading"
                    >パスワードの再設定
                  </v-btn>
                  <div class="marin-bottom-field"></div>
                  <div class="forget-blk">
                    <span class="forgot-text"
                      ><router-link :to="{ name: 'Login' }"
                        >ログイン画面</router-link
                      ></span
                    >
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright-blk">
      <p>
        ©️ {{ new Date().getFullYear() }} 総合研装株式会社, All Rights Reserved.
      </p>
    </div>
  </v-app>
</template>

<script>
import FlashMessage from '@/components/FlashMessage'
export default {
  name: 'ResetPassword',
  components: {
    FlashMessage
  },
  props: {
    success: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // email: '',
      showPassword: false,
      new_password: null,
      confirm_password: null,
      isValid: true,
      isLoading: false,
      error: null,
      rules: {
        // email: [
        //   v => !!v || 'E-mail required',
        //   v =>
        //     /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        //     'E-mail must be valid'
        // ],
        new_password: [
          v => !!v || 'パスワードを入力してください。',
          v =>
            (v && v.length >= 6) ||
            'パスワードを6文字以上の長さで入力してください。'
          // v =>
          //   /^.*(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()_\-+={\[}\]|\:;"\'<,>\.\?\/]).*$/.test(
          //     v
          //   ) ||
          //   'Password must contain uppercase, lowercase, number and special character.'
        ],
        confirm_password: [
          v => !!v || 'パスワードを入力してください。',
          v =>
            (v && v.length >= 6) ||
            'パスワードを6文字以上の長さで入力してください。',
          v =>
            v === this.new_password ||
            'パスワード入力欄とパスワード再入力欄に同じ文字列が入力されていません。'
          // v =>
          //   /^.*(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()_\-+={\[}\]|\:;"\'<,>\.\?\/]).*$/.test(
          //     v
          //   ) ||
          //   'Password must contain uppercase, lowercase, number and special character.'
        ]
      }
    }
  },

  methods: {
    submit() {
      this.isLoading = true
      let email = this.$route.params.email
      let token = this.$route.params.token
      let password_confirmation = this.confirm_password
      let password = this.new_password
      this.$store
        .dispatch('AUTH_RESET', {
          email,
          password_confirmation,
          password,
          token
        })
        .then(
          response => {
            this.isLoading = false
            if (response.status >= 200 && response.status <= 299) {
              this.email = ''
              this.new_password = ''
              this.confirm_password = ''
              this.$router.push({
                query: { success: true }
              })
            }
          },
          error => {
            throw error
          }
        )
        .catch(error => {
          this.isLoading = false
          var err = error.response.data.errors
          if (err) {
            if (err.token) this.error = err.token
            else this.error = err.email[0]
          }
        })
    },
    login() {}
  }
}
</script>

<style scoped src="./Auth.scss" lang="scss" />
